/* Mixins */
.device-detail-component[data-v-a634f8ce]  .rowBackRed {
  background-color: rgba(255, 127, 0, 0.2) !important;
}
.device-detail-component .deviceInfo[data-v-a634f8ce]  .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 120px;
  padding: 16px;
}
.device-detail-component .deviceConfiguration[data-v-a634f8ce]  .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 150px;
  padding: 16px;
}
.device-detail-component[data-v-a634f8ce]  .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px;
}
.device-detail-component .minName[data-v-a634f8ce] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.device-detail-component .minName[data-v-a634f8ce] {
    max-width: 320px;
}
}
@media only screen and (max-width: 1600px) {
.device-detail-component .minName[data-v-a634f8ce] {
    max-width: 230px;
}
}
.device-detail-component .minIdentifier[data-v-a634f8ce] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.device-detail-component .minIdentifier[data-v-a634f8ce] {
    max-width: 200px;
}
}
@media only screen and (max-width: 1600px) {
.device-detail-component .minIdentifier[data-v-a634f8ce] {
    max-width: 140px;
}
}
.device-detail-component .maxName[data-v-a634f8ce] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.device-detail-component .maxName[data-v-a634f8ce] {
    max-width: 230px;
}
}
@media only screen and (max-width: 1600px) {
.device-detail-component .maxName[data-v-a634f8ce] {
    max-width: 140px;
}
}
.device-detail-component .maxIdentifier[data-v-a634f8ce] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.device-detail-component .maxIdentifier[data-v-a634f8ce] {
    max-width: 180px;
}
}
@media only screen and (max-width: 1600px) {
.device-detail-component .maxIdentifier[data-v-a634f8ce] {
    max-width: 91px;
}
}
.device-detail-component .mediaWidth[data-v-a634f8ce] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.device-detail-component .mediaWidth[data-v-a634f8ce] {
    max-width: 285px;
}
}
@media only screen and (max-width: 1600px) {
.device-detail-component .mediaWidth[data-v-a634f8ce] {
    max-width: 140px;
}
}
.device-detail-component .table-operate-btn-group[data-v-a634f8ce] {
  margin-bottom: 16px;
}
.device-detail-component .attribute-label[data-v-a634f8ce] {
  min-width: 100px;
}
.device-detail-component[data-v-a634f8ce]  .jtl-select-group-addon {
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  padding: 0 7.5px;
  background: #fafafa;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
}
.device-detail-component[data-v-a634f8ce]  .ant-input-group-compact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.driver-model[data-v-a634f8ce] {
  border: 2px solid #e8e8e8;
  padding: 16px 0;
  margin: 0 16px 16px 16px;
}
.driver-title[data-v-a634f8ce] {
  margin: 0 16px 16px 16px;
  line-height: 1.5;
}
.driver-title .descriptions-title[data-v-a634f8ce] {
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.prop-config-edit[data-v-a634f8ce] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.prop-config-edit div[data-v-a634f8ce] {
  margin-bottom: 8px;
  margin-right: 4px;
  -webkit-box-flex: calc(50% - 4px);
      -ms-flex: calc(50% - 4px);
          flex: calc(50% - 4px);
}
.prop-config-edit div[data-v-a634f8ce]:last-child {
  margin-bottom: 0px;
}