/* Mixins */
.device-attribute-list-component .refresh-wrapper[data-v-eac14d80] {
  margin-bottom: 8px;
}
.device-attribute-list-component .refresh-wrapper .refresh-switch[data-v-eac14d80] {
  vertical-align: bottom;
}
@media only screen and (max-width: 1920px) {
.device-attribute-list-component .definitionRefsWidth[data-v-eac14d80] {
    max-width: 120px;
}
.device-attribute-list-component .nameWidth[data-v-eac14d80] {
    max-width: 300px;
}
.device-attribute-list-component .identifierWidth[data-v-eac14d80] {
    max-width: 300px;
}
.device-attribute-list-component .valueDescWidth[data-v-eac14d80] {
    max-width: 180px;
}
}
@media only screen and (max-width: 1600px) {
.device-attribute-list-component .definitionRefsWidth[data-v-eac14d80] {
    max-width: 80px;
}
.device-attribute-list-component .nameWidth[data-v-eac14d80] {
    max-width: 180px;
}
.device-attribute-list-component .identifierWidth[data-v-eac14d80] {
    max-width: 95px;
}
.device-attribute-list-component .typeWidth[data-v-eac14d80] {
    max-width: 60px;
}
.device-attribute-list-component .valueDescWidth[data-v-eac14d80] {
    max-width: 90px;
}
.device-attribute-list-component .updateTimeWidth[data-v-eac14d80] {
    max-width: 220px;
}
}
.device-attribute-list-component .ellipsis[data-v-eac14d80] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}