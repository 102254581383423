/* Mixins */
.device-list-component {
  min-width: 1500px !important;
}
.device-list-component .table-header-button {
  line-height: 1.499 !important;
}
.table-column-popover .ant-popover-inner-content {
  padding: 8px 0;
}
.table-column-popover .ant-popover-inner-content .ant-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  height: 32px;
  margin: 0;
  cursor: pointer;
}