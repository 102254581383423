/* Mixins */
.deviceInfo[data-v-f716640a]  .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 120px;
  padding: 16px;
}
.deviceInfo[data-v-f716640a]  .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px;
}
.cardPage[data-v-f716640a] {
  margin-top: 20px;
}